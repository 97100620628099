const { appConfig = {} } = window;

export const features = {
  newOrdersScreen: 'REACT_APP_FEATURE_NEW_ORDERS_SCREEN',
  newLoadCardScreen: 'REACT_APP_FEATURE_NEW_LOAD_CARD_SCREEN',
  cardReplacement: 'REACT_APP_FEATURE_CARD_REPLACEMENT',
  ssoConfiguration: 'REACT_APP_FEATURE_SSO_CONFIGURATION',
  searchCardByPhone: 'REACT_APP_FEATURE_SEARCH_CARDS_BY_PHONE',
  showPartnerMvc: 'REACT_APP_FEATURE_SHOW_PARTNER_MVC',
};

function useAppConfig() {
  function isFeatureEnabled(name) {
    return appConfig[name] === 'true';
  }

  return { isFeatureEnabled };
}

export default useAppConfig;
