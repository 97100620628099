import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import CurrencyFormat from 'react-currency-format';
import AddCard from '../../../components/LoadCard/AddCard';
import {
  CLEAR_CARDS,
  RESET_ADD_CARD_FIELDS,
  RESET_LOAD_CARD,
} from '../../../actions/LoadCardActions';
import {
  ORDER_PREPARED,
  ORDER_PREPARE_RESET,
  ORDER_PREPARING,
  ORDER_PREPARING_ERROR,
  getManageOrdersByDeliveryMethod,
} from '../../../actions/ManageOrdersActions';
import FailAlert from '../../../components/Alert/FailAlert';
import SuccessAlert from '../../../components/Alert/SuccessAlert';
import ValidationErrors from '../../../components/Alert/ValidationErrors';
import { generateOperationID } from '../../../utils/Helper';
import ButtonLoading from '../../../assets/svg/ButtonLoading';
import { GF_DARK_BLUE } from '../../../utils/Constants';
import { issuingAPI } from '../../../services/Axios';
import { useOrdersGroupedByAmount } from '../../../hooks/useOrderDetails';
import Money from '../../../components/Money';

export default function ({ orderDetails, onClose, onPrepare }) {
  const { t } = useTranslation();
  const { selectedTag } = useSelector((state) => state.tag);
  const { cards } = useSelector(({ loadCard }) => loadCard);
  const dispatch = useDispatch();
  const ordersByAmount = useOrdersGroupedByAmount({ order: orderDetails });
  const { orderPreparing, orderPrepared, orderPreparingError } = useSelector(
    (state) => state.manageOrders,
  );
  const { validationErrors } = useSelector((state) => state.commonReducer);

  useEffect(() => {
    dispatch({ type: ORDER_PREPARE_RESET });
  }, []);

  const prepareAllCards = () => {
    const payload = cards.map(({ token, amount }) => {
      return { token, amount }; // x100 to match with the expected format
    });

    dispatch({ type: ORDER_PREPARING });

    const headers = {
      'operation-id': generateOperationID(),
    };

    issuingAPI
      .post(
        `tag/${selectedTag.id}/global-order/${orderDetails.reference}/prepare`,
        { tokens: payload },
        { headers },
      )
      .then((res) => {
        onPrepare();
        dispatch({ type: ORDER_PREPARED, payload: res.data });
        setTimeout(() => {
          onClose();
        }, 3000);
      })
      .catch((error) => {
        dispatch({ type: ORDER_PREPARING_ERROR, payload: error });
      });
  };

  if (!orderDetails) {
    return <></>;
  }

  const resetCards = () => {
    dispatch({ type: ORDER_PREPARE_RESET });
    dispatch({ type: RESET_ADD_CARD_FIELDS });
    if (cards.length !== 0) {
      dispatch({ type: CLEAR_CARDS });
      dispatch({ type: RESET_LOAD_CARD });
    }
  };

  return (
    <>
      <header className={''}>
        <div className={'flex justify-between items-center mb-5'}>
          <h1 className={'uppercase font-bold text-md'}>
            {t('Prepare order')}
          </h1>
        </div>
        <div className={'flex justify-between items-center mb-5'}>
          <h1 className={'font-bold text-md'}>
            {`${t('Order')} - ${orderDetails.reference}`}
          </h1>
        </div>
      </header>
      <section>
        <div className="rounded border max-w-sm bg-gfPeriwinkle mb-5">
          <table className="w-full">
            <thead>
              <tr>
                <th className="text-xs font-normal  py-1 text-center px-2 ">{`${t('Cards')}`}</th>
                <th className="text-xs font-normal  py-1 px-2 text-center">{`${t('quantity')}`}</th>
                <th className="text-xs font-normal  py-1 px-2 text-center">{`${t('Amount')}`}</th>
              </tr>
            </thead>
            <tbody>
              {ordersByAmount.map((byAmount) => (
                <tr key={byAmount.amount}>
                  <td className="text-center  px-2">
                    <div className="flex font-MulishBold justify-center ">
                      <Money
                        value={byAmount.amount}
                        currency={selectedTag.currency}
                      />
                    </div>
                  </td>
                  <td className="font-MulishBold text-center px-2">
                    {byAmount.quantity}
                  </td>
                  <td className="text-center  px-2">
                    <div className="flex font-MulishBold justify-center ">
                      <Money
                        value={byAmount.total}
                        currency={selectedTag.currency}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </section>
      <section className={'my-4'}>
        <AddCard />
      </section>
      <footer className={'flex flex-col justify-end items-end mt-10'}>
        <div className="font-bold text-xl flex flex-row justify-end text-gfDarkBlue space-x-3">
          <span>{t('Total')}:</span>
          <div className="flex justify-start text-gfLightBlue font-MulishBlack ">
            <CurrencyFormat
              decimalScale={2}
              fixedDecimalScale={true}
              value={cards.reduce(
                (a, card) => (a += parseFloat(card.amount)),
                0,
              )}
              displayType={'text'}
              thousandSeparator={true}
            />
            <span className="ml-1">{`${selectedTag.currency}`}</span>
          </div>
        </div>
        <div className={'flex flex-row  space-x-10 mt-5'}>
          <button
            onClick={prepareAllCards}
            disabled={cards?.length === 0}
            className={`${
              !cards?.length
                ? ' gap-2 bg-opacity-50 pointer-events-none'
                : 'opacity-100'
            } flex flex-row transition bg-gfCoral p-2 px-8 rounded text-center mx-auto font-semibold`}
          >
            {orderPreparing && <ButtonLoading color={GF_DARK_BLUE} />}
            {t('Prepare cards')}
          </button>
          <button
            onClick={resetCards}
            className={
              ' bg-gfPeriwinkle p-2 px-8 rounded text-center mx-auto font-semibold'
            }
          >
            {t('Reset')}
          </button>
        </div>
      </footer>
      <div className={'flex flex-row justify-center items-center my-10'}>
        {orderPreparingError && (
          <FailAlert message={orderPreparingError.message} />
        )}
        {orderPrepared === '' && <SuccessAlert message={t('Success')} />}
        {validationErrors && (
          <div className="py-2">
            <ValidationErrors {...validationErrors} />
          </div>
        )}
      </div>
    </>
  );
}
