import React, { useRef, useState, useEffect } from 'react';
import CurrencyFormat from 'react-currency-format';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { useSelector, useDispatch } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import CloseIcon from '../../../assets/svg/CloseIcon';
import FailAlert from '../../Alert/FailAlert';
import SuccessAlert from '../../Alert/SuccessAlert';
import ValidationErrors from '../../Alert/ValidationErrors';

import {
  confirmPanByToken,
  sendCardByEmail,
} from '../../../actions/ReportByChannelActions';
import {
  RESET_DAILY_REPORT_SEND_BY_EMAIL,
  RESET_PAN_VERIFICATION_ERRORS,
} from '../../../actions/DailyReportActions';
import { RESET_VALIDATION_ERRORS } from '../../../actions/CommonActions';
import { EMAIL_SEND_RESET } from '../../../actions/LoadCardActions';
import ButtonLoading from '../../../assets/svg/ButtonLoading';
import { GF_DARK_BLUE } from '../../../utils/Constants';

ReactModal.setAppElement('#root');

export default function PrintOne({
  modalVisibility,
  closeHandler,
  transferDetails,
}) {
  const { t } = useTranslation();
  const componentRef = useRef();
  const dispatch = useDispatch();
  const { selectedTag, tagCurrency } = useSelector((state) => state.tag);
  const { user } = useSelector((state) => state.auth);

  const printReceipt = useReactToPrint({
    content: () => componentRef.current,
  });

  const {
    panVerified,
    panVerificationFailed,
    panVerifyError,
    loadingPanVerification,
    ticketEmailHistory,
    ticketEmailHistoryLoader,
  } = useSelector((state) => state.dailyReport);

  const { sendingTicketViaEmail, emailSuccess, emailError } = useSelector(
    (state) => state.dailyReport,
  );
  const { selectedLanguage } = useSelector((state) => state.language);
  const { validationErrors } = useSelector((state) => state.commonReducer);

  const [email, setEmail] = useState('');
  const [pan, setPan] = useState('');
  const [displayEmail, setDisplayEmail] = useState('');
  const [showHistory, setShowHistory] = useState(false);
  const [validationError, setValidationError] = useState(null);

  const confirmPan = () => {
    const regex = /^\d{4}$/;
    if (!regex.test(pan)) {
      dispatch({ type: RESET_VALIDATION_ERRORS });
      dispatch({ type: RESET_PAN_VERIFICATION_ERRORS });
      setValidationError('PAN-should-be-4-characters-long-and-only-digits');
      return;
    }
    dispatch(confirmPanByToken(pan, transferDetails.cmapi_public_token));
    setValidationError(null);
  };
  const sendEmail = () => {
    dispatch({ type: RESET_VALIDATION_ERRORS });
    dispatch(
      sendCardByEmail(
        email,
        transferDetails.cmapi_public_token,
        selectedLanguage,
        transferDetails.ticket_number,
      ),
    );
  };

  window.addEventListener('clear_email_load_card_ticket', () => {
    setDisplayEmail(email);
    setEmail('');
    setPan('');
  });

  return (
    <ReactModal
      isOpen={modalVisibility}
      contentLabel="Minimal Modal Example"
      className="bg-gray-700 z-50"
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(23, 35, 82, 0.6)',
          zIndex: 9999,
          backdropFilter: 'blur(8px)',
        },
        content: {
          position: 'absolute',
          top: '50px',
          left: '100px',
          right: '100px',
          bottom: '100px',

          backgroundColor: 'rgba(23, 35, 82, 0.0)',
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          borderRadius: '4px',
          outline: 'none',
          padding: '50px',
          marginLeft: 'auto',
          marginRight: 'auto',
          textAlign: 'center',
        },
      }}
    >
      <div className="flex flex-col max-w-xl mx-auto bg-gray-100">
        <div className="flex justify-between p-5">
          {user.permissions.includes('send-ticket-via-email') &&
          window?.appConfig.REACT_APP_FEATURE_SEND_TICKET_VIA_EMAIL ===
            'true' ? (
            <div className="font-MulishBold">{t('gift-card-load-receipt')}</div>
          ) : (
            <div className="flex flex-row justify-center items-center">
              <button
                className="focus:outline-none bg-gfCoral text-base rounded font-MulishBold hover:bg-opacity-75 p-2 w-32"
                onClick={printReceipt}
              >
                {t('Print')}
              </button>
            </div>
          )}

          <button
            onClick={() => {
              closeHandler(!modalVisibility);
              setEmail('');
              setPan('');
              setValidationError(null);
              dispatch({ type: RESET_DAILY_REPORT_SEND_BY_EMAIL });
              dispatch({ type: EMAIL_SEND_RESET });
            }}
          >
            <CloseIcon />
          </button>
        </div>
        {user.permissions.includes('send-ticket-via-email') &&
          window?.appConfig.REACT_APP_FEATURE_SEND_TICKET_VIA_EMAIL ===
            'true' && <hr className="h-1 w-full bg-gray-100 my-2"></hr>}
        {user.permissions.includes('send-ticket-via-email') &&
          window?.appConfig.REACT_APP_FEATURE_SEND_TICKET_VIA_EMAIL ===
            'true' && (
            <div className="flex flex-col py-2 px-5 text-gfDarkBlue">
              <div className="text-left mb-1">
                {t('enter-last-pan-of-the-card')}
              </div>
              <div className="flex flex-row gap-5 mb-4">
                <input
                  value={pan}
                  onChange={(event) => setPan(event.target.value)}
                  placeholder={t('type-the-4-last-digit')}
                  autoComplete="false"
                  className="h-10 w-full focus:border-kadozBlack pl-2 text-gfDarkBlue rounded border border-kadozSemiGray focus:outline-none"
                />
                <button
                  onClick={confirmPan}
                  disabled={pan === ''}
                  className="border rounded w-32 p-2 text-gfDarkBlue w-32 bg-gfCoral font-MulishBold hover:bg-opacity-75  items-center justify-center flex flex-row focus:outline-none focus:border-kadozBlack font-medium"
                >
                  {loadingPanVerification && (
                    <ButtonLoading color={GF_DARK_BLUE} />
                  )}
                  {t('Confirm')}
                </button>
              </div>
              {(panVerifyError ||
                panVerificationFailed ||
                validationError !== null) && (
                <div className="flex flex-col my-5 text-sm justify-center">
                  {validationError && (
                    <FailAlert message={t(validationError)} />
                  )}
                  {panVerifyError && (
                    <FailAlert message={panVerifyError.message} />
                  )}
                  {validationErrors && (
                    <div className="py-2">
                      <ValidationErrors {...validationErrors} />
                    </div>
                  )}
                  {panVerificationFailed && (
                    <FailAlert message={`${t('pan-verification-failed')}!`} />
                  )}
                </div>
              )}

              {panVerified && (
                <div className="flex flex-col">
                  <div className="mb-1 text-left">{t('by-email')}: </div>
                  <div className="flex flex-row gap-5">
                    <div className="w-full flex flex-col justify-right items-right">
                      <input
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        placeholder={t('type-in-email')}
                        autoComplete="false"
                        className="h-10 w-full focus:border-kadozBlack pl-2 text-gfDarkBlue rounded border border-kadozSemiGray focus:outline-none"
                      />
                      {ticketEmailHistory !== null &&
                        ticketEmailHistory.length !== 0 && (
                          <div className="flex flex-row gap-5 justify-end my-1">
                            {!showHistory ? (
                              <button
                                onClick={(e) => setShowHistory(true)}
                                className="focus:outline-none font-bold ml-2 text-gfLightBlue hover:text-opacity-75 text-xs  hover:underline"
                              >
                                {t('show-history')}
                              </button>
                            ) : (
                              <button
                                onClick={(e) => setShowHistory(false)}
                                className="focus:outline-none font-bold ml-2 text-gfLightBlue hover:text-opacity-75 text-xs  hover:underline"
                              >
                                {t('hide-history')}
                              </button>
                            )}
                          </div>
                        )}
                    </div>
                    <button
                      onClick={sendEmail}
                      disabled={email === ''}
                      className="h-10 border rounded w-32 p-2 text-gfDarkBlue bg-gfCoral font-MulishBold hover:bg-opacity-75  items-center justify-center flex flex-row focus:outline-none focus:border-kadozBlack font-medium"
                    >
                      {sendingTicketViaEmail && (
                        <ButtonLoading color={GF_DARK_BLUE} />
                      )}
                      {t('Send')}
                    </button>
                  </div>

                  <table
                    className="text-left bg-white mt-5"
                    style={{ display: showHistory ? '' : 'none' }}
                  >
                    {ticketEmailHistory !== null &&
                      ticketEmailHistory.map((history) => (
                        <tr className="border border-kadozSemiGray">
                          <td className="p-1">{history.email}</td>
                          <td>{history.creation_date}</td>
                          <td>{t('Delivered')}</td>
                        </tr>
                      ))}
                  </table>
                </div>
              )}
              {(emailSuccess || emailError) && (
                <div className="flex flex-col my-5 text-sm justify-center">
                  {emailSuccess && (
                    <SuccessAlert
                      title="Success"
                      message={`${t('Email-was-sent-successfully')} ${displayEmail}`}
                    />
                  )}
                  {emailError && <FailAlert message={emailError.message} />}
                  {validationErrors && (
                    <div className="py-2">
                      <ValidationErrors {...validationErrors} />
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        {user.permissions.includes('send-ticket-via-email') &&
          window?.appConfig.REACT_APP_FEATURE_SEND_TICKET_VIA_EMAIL ===
            'true' && <hr className="h-1 w-full bg-gray-100 my-2"></hr>}
        {user.permissions.includes('send-ticket-via-email') &&
          window?.appConfig.REACT_APP_FEATURE_SEND_TICKET_VIA_EMAIL ===
            'true' && (
            <div className="flex flex-row justify-center items-center mb-5">
              <button
                className="focus:outline-none bg-gfCoral text-base rounded font-MulishBold hover:bg-opacity-75 p-2 w-32"
                onClick={printReceipt}
              >
                {t('Print')}
              </button>
            </div>
          )}
        <div
          ref={componentRef}
          style={{
            maxWidth: '165px',
            margin: 'auto',
            backgroundColor: 'white',
            color: 'black',
            fontSize: '10px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              paddingTop: '30px',
            }}
          >
            <img
              style={{ maxWidth: '100px' }}
              src={`${window?.appConfig.REACT_APP_IMAGE_URL}/${selectedTag.logo_file_bw}`}
              alt="logo"
            />
          </div>

          <div
            style={{
              textAlign: 'left',
              fontSize: '16px',
              paddingTop: '30px',
              paddingBottom: '10px',
              fontWeight: 'bolder',
            }}
          >
            {selectedTag.name}
          </div>

          {transferDetails.user && transferDetails.user !== null && (
            <div
              style={{
                display: 'flex',
                paddingTop: '3px',
                paddingBottom: '3px',
              }}
            >
              <span style={{ fontWeight: 'bold' }}>{`${t('User')}: `}</span>
              <span style={{ marginLeft: '4px' }}>{`${String(
                transferDetails.user.firstname.charAt(0),
              ).toUpperCase()} ${String(transferDetails.user.lastname.charAt(0)).toUpperCase()}`}</span>
            </div>
          )}

          <div
            style={{ display: 'flex', paddingTop: '3px', paddingBottom: '3px' }}
          >
            <span style={{ fontWeight: 'bold' }}>{`${t('Date')}: `}</span>
            <span style={{ marginLeft: '4px' }}>
              {transferDetails.reception_date}
            </span>
          </div>

          {transferDetails && (
            <div
              style={{
                textAlign: 'left',
                paddingTop: '3px',
                paddingBottom: '3px',
              }}
            >
              <span
                style={{
                  fontWeight: 'bold',
                  whiteSpace: 'nowrap',
                  display: 'inline-block',
                  marginRight: '4px',
                }}
              >{`${t('Channel')}: `}</span>
              <span
                style={{ textTransform: 'uppercase', display: 'inline-block' }}
              >
                {transferDetails.channel_name}
              </span>
            </div>
          )}

          <div
            style={{ display: 'flex', paddingTop: '3px', paddingBottom: '3px' }}
          >
            <span
              style={{ fontWeight: 'bold' }}
            >{`${t('Sequence-number')}: `}</span>
            <span style={{ marginLeft: '4px' }}>
              {transferDetails.cmapi_sequence_number}
            </span>
          </div>

          <div
            style={{ display: 'flex', paddingTop: '3px', paddingBottom: '3px' }}
          >
            <span style={{ fontWeight: 'bold' }}>{`${t('Card')}: `}</span>
            <span style={{ marginLeft: '4px' }}>
              {transferDetails.cmapi_public_token}
            </span>
          </div>

          <div className="flex ">
            <span style={{ fontWeight: 'bold' }}>{`${t('Amount')}: `}</span>
            <div style={{ marginLeft: '4px' }}>
              <CurrencyFormat
                decimalScale={2}
                fixedDecimalScale={true}
                value={transferDetails.amount}
                displayType={'text'}
                thousandSeparator={true}
              />
              <span style={{ marginLeft: '4px' }}>{tagCurrency}</span>
            </div>
          </div>

          <div style={{ textAlign: 'center', padding: '20px 0px' }}>
            <div>{selectedTag.name}</div>
            <div className="text-center">{`${selectedTag.address}, ${selectedTag.zip}, ${selectedTag.city}, ${selectedTag.country_code}`}</div>
            <div style={{ width: '155px', wordBreak: 'break-all' }}>
              {selectedTag.support_email}
            </div>
            <div>{selectedTag.support_phone}</div>
            <div>{selectedTag.website}</div>
          </div>

          <div
            style={{
              textAlign: 'center',
              paddingBottom: '30px',
            }}
          >
            {selectedTag.ticket_footer}
          </div>
        </div>
      </div>
    </ReactModal>
  );
}
