/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getAccessToken } from './actions/AuthActions';
import AppLayout from './components/AppLayout';
import Welcome from './components/Welcome';
import Callback from './components/Callback';
import LoadCard from './components/LoadCard/LoadCard';
import NewLoadCard from './components/Pages/Cards/LoadCards/LoadCard';
import CardInformation from './components/Pages/Cards/CardInformation/CardInformation';
import ShopList from './components/Pages/Shops/ListOfShops/ShopList';
import ActivatePromoCards from './components/Pages/Cards/ActivatePromoCards/ActivatePromoCards';
import ShoppingCenterBalance from './components/Pages/Shops/ShoppingCenterBalance/ShoppingCenterBalance';
import ManageSales from './components/Pages/Reports/OnlineSales/ManageSales';
import ManageOrders from './components/Pages/Orders/ManageOrders';
import FeesReport from './components/Pages/Reports/FeesReport/FeesReport';
import ExpiryReport from './components/Pages/Reports/ExpiryReport/ExpiryReport';
import ReportByChannel from './components/Pages/ReportByChannel/ReportByChannel';
import DailyReport from './components/Pages/Reports/DailyReport/DailyReport';
import DailyReportOld from './components/Pages/Reports/DailyReport/DailyReportOld';
import ProductReport from './components/Pages/Reports/ProductReport/ProductReport';
import SalesByMerchant from './components/Pages/More/SalesByMerchant/SalesByMerchant';
import WeeklyBreakdown from './components/Pages/More/WeeklyBreakdown/WeeklyBreakdown';
import SalesSummaryReport from './components/Pages/More/SalesSummary/SalesSummaryReport';
import MVCManagement from './components/Pages/MVC/MVCManagement';
import MVCLoad from './components/Pages/MVC/MVCLoad';
import System from './components/Alert/System';
import { REMOVE_HAS_CB_ALERT, TAG_CHANGED } from './actions/TagActions';
import CardOrderList from './components/Pages/Cards/CardOrders/CardOrderList';
import DeliveryAddress from './components/Pages/Cards/DeliveryAddress/DeliveryAddress';
import OrderCards from './components/Pages/Cards/CardOrders/OrderCards';
import CardTransferList from './components/Pages/Cards/CardBalanceTransfer/CardTransferList';
import IdleTimerContainer from './components/Alert/IdleTimerContainer';
import MVCtoMVCTransfer from './components/Pages/MVCManagement/MVCToMVCTransfer/MVCToMVCTransfer';
import MVCTransferHistory from './components/Pages/MVC/MVCTransferHistory';
import NotificationList from './components/Notifications/NotificationList';
import ManageShops from './components/Pages/Shops/ManageShops/ManageShops';
import Administration from './components/Admin/Administration';
import UserManagement from './components/Admin/UserManagement/UserManagement';
import TicketManagement from './components/Admin/TicketManagement/TicketManagement';
import CardStock from './components/Pages/Reports/CardStock/CardStock';
import KPIs from './components/Admin/KPIs/KPIs';
import { SET_ADMIN_VIEW } from './actions/Admin/AdminActions';
import EditShoppingCenter from './components/Admin/ShoppingCenter/EditShoppingCenter';
import { RESET_APP_COUNT } from './actions/CommonActions';
import BlockedCardsReport from './components/Admin/BlockedCardsReport/BlockedCardsReport';
import ManageSalesB2b from './components/Pages/Reports/OnlineSales/ManageSalesB2b';
import CustomScriptComponent from './components/Core/CustomScriptComponent';
import DigitisationReport from './components/Pages/Reports/DigitisationReport/DigitisationReport';
import ManagementPerformanceReport from './components/Pages/Reports/ManagementPerformanceReport/ManagementPerformanceReport';
import TransactionOverview from './components/Pages/MVC/TransactionOverview';
import CardUsageAtMerchantReport from './components/Pages/Reports/CardUsageAtMerchantReport/CardUsageAtMerchantReport';
import PlaceB2BOrders from './components/Pages/Orders/PlaceB2BOrders';
import EditSsoConfiguration from './components/Admin/SsoConfiguration/EditSsoConfiguration';
import useAppConfig, { features } from './hooks/useAppConfig';
import CardToCard from './components/Pages/Cards/CardReplacement/CardToCard';
import CardTransfer from './components/Pages/Cards/CardBalanceTransfer/CardTransfer';
import MyPortalCardsLoad from './components/Pages/Reports/MyPortalOperations/MyPortalCardsLoad';

function App(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isFeatureEnabled } = useAppConfig();

  useEffect(() => {
    dispatch({ type: RESET_APP_COUNT });

    const access_token = localStorage.getItem('access_token');
    const auth_token = localStorage.getItem('auth_token');

    const view = localStorage.getItem('view');

    if (view === 'admin') dispatch({ type: SET_ADMIN_VIEW, payload: true });

    if (access_token && auth_token) {
      dispatch(getAccessToken(auth_token, history));
    } else if (auth_token) {
      history.push('/');
    } else {
      localStorage.setItem('path', history.location.pathname);
      history.location.pathname = history.location.pathname.includes(
        '/sso/login',
      )
        ? `${history.location.pathname}/`.replace('/sso/login', '')
        : '/';
      window.location.assign(
        `${window?.appConfig.REACT_APP_AUTH_SERVER_API}/login${history.location.pathname}${window?.appConfig.REACT_APP_AUTH_CLIENT_ID}`,
      );
    }
  }, []);

  const { selectedTag } = useSelector((state) => state.tag);
  const manageSalesDisplayName = selectedTag?.enable_e_shop_b2b_orders
    ? t('manage-sales-b2c')
    : t('Manage-sales');
  let dailyReportTickOptionEnable = true;
  if (
    window?.appConfig.REACT_APP_FEATURE_ADD_TICK_OPTION_ENABLE === 'false' &&
    selectedTag?.id !=
      window?.appConfig.REACT_APP_FEATURE_ADD_TICK_OPTION_ENABLE_SC
  ) {
    dailyReportTickOptionEnable = false;
  }

  const isSSOConfigurationEnabled = isFeatureEnabled(features.ssoConfiguration);

  const adminProtectedRouts = [
    {
      path: '/administration/user-management',
      name: t('User-Management'),
      component: UserManagement,
      guard_name: 'admin-user-management',
    },
    {
      path: '/administration/ticket-management',
      name: t('Ticket-Management'),
      component: TicketManagement,
      guard_name: 'admin-ticket-management',
    },
    {
      path: '/administration/kpis',
      name: t('KPIs'),
      component: KPIs,
      guard_name: 'admin-kpi',
    },
    {
      path: '/administration/shopping-centers',
      name: t('Shopping-Centers'),
      component: EditShoppingCenter,
      guard_name: 'admin-edit-shopping-center',
    },
    {
      path: '/administration/card-stock',
      name: t('card-stock'),
      component: CardStock,
      guard_name: 'admin-card-stock',
    },
    {
      path: '/administration/blocked-cards-report',
      name: t('blocked-cards-report'),
      component: BlockedCardsReport,
      guard_name: 'blocked-card-stock',
    },
    isSSOConfigurationEnabled && {
      path: '/administration/sso-configuration',
      name: t('sso.sso-configuration'),
      component: EditSsoConfiguration,
      guard_name: 'admin-sso-configuration',
    },
  ].filter((route) => route);

  const isNewOrdersScreenEnabled = isFeatureEnabled(features.newOrdersScreen);
  const isNewLoadCardScreenEnabled = isFeatureEnabled(
    features.newLoadCardScreen,
  );
  const isCardReplacementEnabled = isFeatureEnabled(features.cardReplacement);

  const protectedRoutes = [
    {
      path: '/card/load-card',
      name: t('Load-Card'),
      component: LoadCard,
      guard_name: 'load-card',
    },
    isNewLoadCardScreenEnabled && {
      path: '/card/new-load-card',
      name: t('Load-Card'),
      component: NewLoadCard,
      guard_name: 'new-load-card',
    },
    {
      path: '/gift-cards',
      name: t('Gift-Cards'),
      component: ParentLayout,
      routes: [
        {
          path: '/gift-cards/card-information',
          name: t('card-information'),
          component: CardInformation,
          guard_name: 'card-information',
        },
        isCardReplacementEnabled && {
          path: '/gift-cards/card-transfer-new',
          name: t('card-replacements.tab'),
          component: CardToCard,
          guard_name: 'card-to-card-transfer-new',
        },
        {
          path: '/gift-cards/card-transfer',
          name: t('card-to-card-transfer'),
          component: CardTransfer,
          guard_name: 'card-to-card-transfer',
        },
        {
          path: '/gift-cards/card-transfer_list',
          name: t('list-of-card-transfer'),
          component: CardTransferList,
          guard_name: 'list-of-card-transfer',
        },
      ].filter((route) => route),
    },

    {
      path: '/promo-cards',
      name: t('Promo-Cards'),
      component: ParentLayout,
      routes: [
        {
          path: '/promo-cards/activate-promo-cards',
          name: t('activate-promo-cards'),
          component: ActivatePromoCards,
          guard_name: 'activate-promo-cards',
        },
        {
          path: '/promo-cards/order-promo-cards',
          name: t('order-promo-cards'),
          component: OrderCards,
          guard_name: 'order-promo-cards',
        },
        {
          path: '/promo-cards/order-history',
          name: t('order-history'),
          component: CardOrderList,
          guard_name: 'order-history',
        },
        {
          path: '/promo-cards/delivery-address',
          name: t('delivery-addresses'),
          component: DeliveryAddress,
          guard_name: 'delivery-addresses',
        },
      ],
    },
    {
      path: '/shops',
      name: t('Shops'),
      component: ParentLayout,
      routes: [
        {
          path: '/shops/shop-list',
          name: t('list-of-shops'),
          component: ShopList,
          guard_name: 'list-of-shops',
        },
        {
          path: '/shops/manage-shops',
          name: t('manage-shops'),
          component: ManageShops,
          guard_name: 'manage-shops',
        },
      ],
    },

    isNewOrdersScreenEnabled && {
      path: '/orders',
      name: t('Orders'),
      component: ParentLayout,
      routes: [
        {
          path: '/orders/manage-orders',
          name: t('manage-orders.all-orders'),
          component: ManageOrders,
          guard_name: 'manage-orders',
        },
        {
          path: '/orders/place-b2b-order',
          name: t('place-b2b-order.place-b2b-order'),
          component: PlaceB2BOrders,
          guard_name: 'place-b2b-order',
        },
      ],
    },

    {
      path: '/online-sales',
      name: t('Online-Sales'),
      component: ParentLayout,
      routes: [
        {
          path: '/online-sales/manage-sales',
          name: manageSalesDisplayName,
          component: ManageSales,
          guard_name: 'manage-sales',
        },
        {
          path: '/online-sales/manage-sales-b2b',
          name: t('manage-sales-b2b'),
          component: ManageSalesB2b,
          guard_name: 'manage-sales-b2b',
        },
        {
          path: '/online-sales/fees-report',
          name: t('fees-report'),
          component: FeesReport,
          guard_name: 'fees-report',
        },
      ],
    },

    {
      path: '/reports',
      name: t('Report-&-Statistics'),
      component: ParentLayout,
      routes: [
        {
          path: '/reports/shopping-center-balance',
          name: t('shopping-center-balance'),
          component: ShoppingCenterBalance,
          guard_name: 'shopping-center-balance',
        },
        {
          path: '/reports/daily-report',
          name: t('daily-report'),
          component: dailyReportTickOptionEnable ? DailyReport : DailyReportOld,
          guard_name: 'daily-report',
        },
        {
          path: '/reports/channel-report',
          name: t('report-by-channel'),
          component: ReportByChannel,
          guard_name: 'report-by-channel',
        },
        {
          path: '/reports/sales-summary',
          name: t('sales-summary'),
          component: SalesSummaryReport,
          guard_name: 'sales-summary',
        },
        {
          path: '/reports/sales-by-merchant',
          name: t('sales-by-merchant'),
          component: SalesByMerchant,
          guard_name: 'sales-by-merchant',
        },
        {
          path: '/reports/weekly-sales',
          name: t('weekly-breakdown'),
          component: WeeklyBreakdown,
          guard_name: 'weekly-breakdown',
        },

        {
          path: '/reports/expiry-report',
          name: t('expiry-report'),
          component: ExpiryReport,
          guard_name: 'expiry-report',
        },

        {
          path: '/reports/digitisation-report',
          name: t('digitisation-report'),
          component: DigitisationReport,
          guard_name: 'tokenization-report',
        },

        {
          path: '/reports/management-performance-report',
          name: t('management-performance-report'),
          component: ManagementPerformanceReport,
          guard_name: 'management-performance-report',
        },

        {
          path: '/reports/cards-usage-at-merchants',
          name: t('card-usage-at-merchants.card-usage-at-merchants'),
          component: CardUsageAtMerchantReport,
          guard_name: 'cards-usage-at-merchants-report',
        },
      ],
    },

    {
      path: '/myportal-reports',
      name: t('myportal-reports.reports'),
      component: ParentLayout,
      routes: [
        {
          path: '/myportal-reports/myportal-cards-load',
          name: t('myportal-reports.operations'),
          component: MyPortalCardsLoad,
          guard_name: 'new-myportal-daily-loads',
        },
      ],
    },

    {
      path: '/products',
      name: t('Products'),
      component: ParentLayout,
      routes: [
        {
          path: '/products/product-report',
          name: t('Product-report'),
          component: ProductReport,
          guard_name: 'product-report',
        },
      ],
    },

    {
      path: '/mvc-management',
      name: t('MVC-Management'),
      component: ParentLayout,
      routes: [
        {
          path: '/mvc-management/mvc-list',
          name: t('mvc-list'),
          component: MVCManagement,
          guard_name: 'mvc-list',
        },
        {
          path: '/mvc-management/mcv-mcv-transfer',
          name: t('Mvc-to-mvc-transfer'),
          component: MVCtoMVCTransfer,
          guard_name: 'mvc-to-mvc-transfer',
        },
        {
          path: '/mvc-management/mvc-transfer-list',
          name: t('MVC-transfer-History'),
          component: MVCTransferHistory,
          guard_name: 'mvc-transfer',
        },
        {
          path: '/mvc-management/mvc-load',
          name: t('mvc-load'),
          component: MVCLoad,
          guard_name: 'mvc-load',
        },
        {
          path: '/mvc-management/transaction-overview/:token',
          name: t('transaction-overview'),
          component: TransactionOverview,
          guard_name: 'mvc-list',
          on_menu: false,
        },
      ],
    },
  ].filter((route) => route);

  function RouteWithSubRoutes(route) {
    const { selectedTag } = useSelector((state) => state.tag);
    const { user } = useSelector((state) => state.auth);

    return (
      <>
        {selectedTag && user && (
          <Route
            key={Date.now()}
            path={route.path}
            activeClassName="active"
            render={(props) => (
              // pass the sub-routes down to keep nesting
              <route.component {...props} routes={route.routes} />
            )}
          />
        )}
      </>
    );
  }

  function ParentLayout({ routes }) {
    return (
      <Switch>
        {routes.map((route, i) => (
          <RouteWithSubRoutes key={i} {...route} />
        ))}
      </Switch>
    );
  }

  let keyCount = 0;

  window.addEventListener(TAG_CHANGED, (v) => {
    keyCount += 1;
  });

  const closeAlert = (hasCardBrandIds) => {
    dispatch({ type: REMOVE_HAS_CB_ALERT, payload: !hasCardBrandIds });
  };

  function SystemAlert() {
    const { hasCardBrandIds } = useSelector((state) => state.tag);
    const { isAuthenticated } = useSelector((state) => state.auth);

    return (
      !hasCardBrandIds &&
      isAuthenticated && (
        <div className="mx-auto w-full flex justify-center">
          <System closeAlert={() => closeAlert(hasCardBrandIds)} />
        </div>
      )
    );
  }

  const { adminView } = useSelector((state) => state.admin);
  const { hasAdminPrivilege } = useSelector((state) => state.auth);

  return (
    <div>
      <AppLayout
        navigation={
          adminView && hasAdminPrivilege ? adminProtectedRouts : protectedRoutes
        }
      />
      <div className="mt-32 md:mt-40">
        <SystemAlert />
        <IdleTimerContainer />
        <CustomScriptComponent />
        <div key={keyCount} className="mx-auto md:mt-18">
          <Switch>
            <Route exact path="/" component={Welcome} />
            <Route exact path="/callback" component={Callback} />
            <Route
              exact
              path="/notification-list"
              component={NotificationList}
            />
            <Route exact path="/administration" component={Administration} />
            {adminView && hasAdminPrivilege
              ? adminProtectedRouts.map((route, i) => (
                  <RouteWithSubRoutes key={i} {...route} />
                ))
              : protectedRoutes.map((route, i) => (
                  <RouteWithSubRoutes key={i} {...route} />
                ))}
            {/* <Route path="*" component={() => "404 NOT FOUND"} /> */}
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default App;
