/* eslint-disable import/no-anonymous-default-export */
import {
  ERROR_DIGITISATION_REPORT,
  LOADING_DIGITISATION_REPORT,
  RECEIVED_DIGITISATION_REPORT,
  RESET_DIGITISATION_REPORT,
} from '../../actions/DigitisationActions';

const initialState = {
  loadingDigitisationReport: false,
  receivingDigitisationReport: null,
  errorDigitisationReport: null,
  digitisationReportSummary: null,
  devicesList: null,
  populatedList: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_DIGITISATION_REPORT:
      return {
        ...state,
        loadingDigitisationReport: true,
        receivingDigitisationReport: null,
        errorDigitisationReport: null,
        digitisationReportSummary: null,
      };

    case RECEIVED_DIGITISATION_REPORT:
      let temp = [];
      if (action.payload.summary.devices) {
        temp = action.payload.summary.devices;
        temp.reverse();
      }

      return {
        ...state,
        loadingDigitisationReport: false,
        receivingDigitisationReport: action.payload.data,
        errorDigitisationReport: null,
        digitisationReportSummary: action.payload.summary,
        devicesList: temp,
      };

    case ERROR_DIGITISATION_REPORT:
      return {
        ...state,
        loadingDigitisationReport: false,
        receivingDigitisationReport: null,
        errorDigitisationReport: null,
        digitisationReportSummary: action.payload,
      };

    case RESET_DIGITISATION_REPORT:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
}
