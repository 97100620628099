import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '../../../../assets/svg/CloseIcon';
import {
  changeManageSalesStatusByGlobalOrderRef,
  getManageSalesByDeliveryMethod,
  RESET_ORDER_STATUS,
} from '../../../../actions/ManageSaleActions';
import SuccessAlert from '../../../Alert/SuccessAlert';
import FailAlert from '../../../Alert/FailAlert';
import ValidationErrors from '../../../Alert/ValidationErrors';
import ButtonLoading from '../../../../assets/svg/ButtonLoading';
import { GF_DARK_BLUE } from '../../../../utils/Constants';

ReactModal.setAppElement('#root');

export default function OnlineSalesStatusChangeConfirmation({
  isOpen,
  order,
  selectedOrder,
  closeHandler,
  selectedCustomerType,
  selectedStatusChanged,
  chooseDate,
  endDate,
  selectedPageNumber,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, reset, control, setValue } = useForm({
    mode: 'onChange',
  });
  const {
    changingOrderStatus,
    successOrderStatus,
    failOrderStatus,
    validationErrors,
  } = useSelector((state) => state.manageSales);
  const onSubmit = (event) => {
    dispatch(
      changeManageSalesStatusByGlobalOrderRef(
        order.global_order_ref,
        selectedStatusChanged.id,
      ),
    );
  };

  const cancelUpdating = () => {
    dispatch({ type: RESET_ORDER_STATUS });
    closeHandler(!isOpen);
  };

  return (
    <ReactModal
      isOpen={isOpen}
      contentLabel="PrepareOrder"
      className="bg-gray-700 z-50"
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(23, 35, 82, 0.6)',
          zIndex: 9999,
          backdropFilter: 'blur(8px)',
        },
        content: {
          position: 'absolute',
          top: '50px',
          left: '100px',
          right: '100px',
          bottom: '100px',

          backgroundColor: 'rgba(23, 35, 82, 0.0)',
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          borderRadius: '4px',
          outline: 'none',
          padding: '50px',
          marginLeft: 'auto',
          marginRight: 'auto',
          textAlign: 'center',
        },
      }}
    >
      <div className="flex flex-col  mx-auto py-3 px-10 border max-w-lg rounded shadow-lg bg-white text-gfDarkBlue font-MulishRegular">
        <div className="flex justify-between items-center mb-7">
          <div className="font-MulishBold">
            {t('Online-order-status-changed').toUpperCase()}
          </div>
          <button
            className="focus:outline-none"
            onClick={() => {
              dispatch({ type: RESET_ORDER_STATUS });
              closeHandler(!isOpen);
              dispatch(
                getManageSalesByDeliveryMethod(
                  selectedOrder.homeDeliver,
                  selectedOrder.value,
                  selectedCustomerType.value,
                  '',
                  selectedPageNumber,
                  chooseDate,
                  endDate,
                ),
              );
            }}
          >
            <CloseIcon />
          </button>
        </div>

        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col">
            <div className="flex flex-row items-center justify-start mb-7">
              {selectedStatusChanged.id === 'paid' && (
                <div className="font-MulishBold mr-1 text-left">
                  {t('Online-order-status-changed-body-paid')}
                </div>
              )}
              {selectedStatusChanged.id === 'decline' && (
                <div className="font-MulishBold mr-1 text-left">
                  {t('Online-order-status-changed-body-cancel')}
                </div>
              )}
            </div>

            <div className="flex flex-row justify-end">
              {!changingOrderStatus && !successOrderStatus && (
                <button
                  onClick={cancelUpdating}
                  className="w-32 flex flex-row border rounded px-4 py-2 justify-center  font-MulishBold bg-gfPeriwinkle focus:outline-none hover:bg-opacity-75  cursor-pointer"
                >
                  {t('Cancel')}
                </button>
              )}

              {!changingOrderStatus &&
                !successOrderStatus &&
                selectedStatusChanged.id === 'paid' && (
                  <button
                    type="submit"
                    className="w-42 flex flex-row border rounded px-4 py-2 ml-2 justify-center  font-MulishBold bg-gfCoral focus:outline-none hover:bg-opacity-75  cursor-pointer"
                  >
                    <span>{t('make-order-paid')}</span>
                  </button>
                )}
              {!changingOrderStatus &&
                !successOrderStatus &&
                selectedStatusChanged.id === 'decline' && (
                  <button
                    id="makeOrderDeclineButton"
                    type="submit"
                    className="w-42 flex flex-row border rounded px-4 py-2 ml-2 justify-center  font-MulishBold bg-gfCoral focus:outline-none hover:bg-opacity-75  cursor-pointer"
                  >
                    <span>{t('make-order-decline')}</span>
                  </button>
                )}
              {changingOrderStatus && (
                <ButtonLoading color={GF_DARK_BLUE}></ButtonLoading>
              )}
            </div>
            {successOrderStatus && selectedStatusChanged.id === 'paid' && (
              <div className="py-4 space-y-5">
                <SuccessAlert
                  title={t('Done')}
                  message={t('order-status-change-paid-msg')}
                />
              </div>
            )}
            {successOrderStatus && selectedStatusChanged.id === 'decline' && (
              <div className="py-4 space-y-5">
                <SuccessAlert
                  title={t('Done')}
                  message={t('order-status-change-decline-msg')}
                />
              </div>
            )}
            {failOrderStatus && (
              <div className="py-4 space-y-5">
                <FailAlert message={failOrderStatus.message} />
              </div>
            )}
            {validationErrors && (
              <div className="py-4 space-y-5">
                <div className="py-2">
                  <ValidationErrors {...validationErrors} />
                </div>
              </div>
            )}
          </div>
        </form>
      </div>
    </ReactModal>
  );
}
