import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import { Userpilot } from 'userpilot';
import { format } from 'date-fns';
import Container from '../../../UIComponents/Container';
import { GF_DARK_BLUE } from '../../../../utils/Constants';
import {
  getMyPortalCardsLoadCSVExport,
  getMyPortalCardsLoads,
  getMyPortalCardsLoadsSummary,
} from '../../../../actions/MyPortalOperations/MyPortalCardsLoadActions';
import CardInfoWidget from '../../../CardInfoWidget';
import CurrencyFormat from 'react-currency-format';
import {
  checkLoggedInUserPermissions,
  randomString,
} from '../../../../utils/Helper';
import Table from '../../../Table';
import ButtonLoading from '../../../../assets/svg/ButtonLoading';
import Money from '../../../Money';
import ChevronUp from '../../../../assets/svg/ChevronUp';
import Print from '../../../../assets/svg/Print';
import MessageAlert from '../../../Alert/MessageAlert';
import FailAlert from '../../../Alert/FailAlert';
import ValidationErrors from '../../../Alert/ValidationErrors';
import { Link } from 'react-router-dom';
import { RESET_APP_COUNT } from '../../../../actions/CommonActions';
import ChevronDown from '../../../../assets/svg/ChevronDown';
import SelectUserField from '../../../Input/SelectUserField';
import SelectDeskField from '../../../Input/SelectDeskField';
import DatePickerField from '../../../Input/DatePickerField';
import LoadCardPrintReceipt from './LoadCardPrintReceipt';

function AdditionalProducts({ products, total, currency }) {
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="font-MulishBold">
        <Money value={total} currency={currency} />
      </div>
      {products.map((product) => (
        <div>
          <Money value={product.price} currency={currency} /> x
          {`${product.quantity}`}
        </div>
      ))}
    </div>
  );
}

function PaymentMethods({ payments, total, currency }) {
  return (
    <div className="flex flex-col justify-center items-center">
      {payments.map((payment) => (
        <div>
          {`${payment.paymentMethod}`} -
          <Money value={payment.amount} currency={currency} />
        </div>
      ))}
    </div>
  );
}

function LoadRow({ load, currency, setRowIsOpen, rowIsOpen }) {
  const dispatch = useDispatch();

  const [printReceiptOpen, setPrintReceiptOpen] = useState(false);
  const canAccessCardInformation = useMemo(
    () => checkLoggedInUserPermissions('card-information'),
    [checkLoggedInUserPermissions],
  );

  function linkOnClickHandler() {
    dispatch({ type: RESET_APP_COUNT });
  }

  const printReceiptCloseHandler = () => {
    setPrintReceiptOpen(!printReceiptOpen);
  };

  const openHideActionButton = () => {
    setRowIsOpen(rowIsOpen ? null : load.ticket_number);
  };

  return (
    <>
      <Table.Row
        data-testid={load.ticket_number}
        className="font-MulishRegular"
      >
        <Table.Cell data-testid="date">{load.date}</Table.Cell>
        <Table.Cell data-testid="ticketNumber">{load.ticket_number}</Table.Cell>
        <Table.Cell data-testid="user">{`${load.user === '' ? '-' : load.user}`}</Table.Cell>
        <Table.Cell data-testid="tokens">
          {load.tokens.length > 1 ? (
            <span className="font-MulishBold">x{load.tokens.length}</span>
          ) : canAccessCardInformation ? (
            <Link
              onClick={linkOnClickHandler}
              className="underline"
              to={`/gift-cards/card-information?token=${load?.tokens[0].tokenNumber}`}
            >
              {load.tokens[0].tokenNumber}
            </Link>
          ) : (
            `${load.tokens[0].tokenNumber}`
          )}
        </Table.Cell>
        <Table.Cell data-testid="cardLoad">
          <Money value={load.card_load_value_total} currency={currency} />
        </Table.Cell>
        <Table.Cell data-testid="cardLoadFee">
          <Money value={load.card_load_fee_total} currency={currency} />
        </Table.Cell>
        <Table.Cell data-testid="additionalProducts">
          {load.additional_products.length !== 0 ? (
            <AdditionalProducts
              products={load.additional_products}
              total={load.additional_products_total}
              currency={currency}
            />
          ) : (
            '-'
          )}
        </Table.Cell>
        <Table.Cell data-testid="paymentMethods">
          {load.payments.length !== 0 ? (
            <PaymentMethods
              payments={load.payments}
              total={load.payment_amount_total}
              currency={currency}
            />
          ) : (
            '-'
          )}
        </Table.Cell>
        <Table.Cell data-testid="paymentAmount">
          <Money value={load.payment_amount_total} currency={currency} />
        </Table.Cell>
        {load.tokens?.length > 1 ? (
          <Table.Cell className="text-center" data-testid="expand">
            <div className="flex flex-col gap-5 justify-center items-center">
              <button
                className="focus:outline-none"
                id={load.ticket_number}
                onClick={openHideActionButton}
              >
                {rowIsOpen ? <ChevronUp /> : <ChevronDown />}
              </button>

              {rowIsOpen && (
                <button
                  onClick={() => {
                    setPrintReceiptOpen(!printReceiptOpen);
                  }}
                >
                  <Print />
                </button>
              )}
            </div>
          </Table.Cell>
        ) : (
          <Table.Cell data-testid="print">
            <button
              onClick={() => {
                setPrintReceiptOpen(!printReceiptOpen);
              }}
            >
              <Print />
            </button>
          </Table.Cell>
        )}
      </Table.Row>

      {rowIsOpen &&
        load.tokens.length > 1 &&
        load.tokens.map((token) => (
          <Table.Row
            data-testid={token}
            key={token.tokenNumber}
            style={{ backgroundColor: '#f4f4f4' }}
          >
            <Table.Cell data-testid="date"></Table.Cell>
            <Table.Cell data-testid="ticketNumber"></Table.Cell>
            <Table.Cell data-testid="user"></Table.Cell>
            <Table.Cell data-testid="tokens">
              {canAccessCardInformation ? (
                <Link
                  onClick={linkOnClickHandler}
                  className="underline"
                  to={`/gift-cards/card-information?token=${token.tokenNumber}`}
                >
                  {token.tokenNumber}
                </Link>
              ) : (
                `${token.tokenNumber}`
              )}
            </Table.Cell>
            <Table.Cell data-testid="cardLoad">
              <Money value={token.amount} currency={currency} />
            </Table.Cell>
            <Table.Cell data-testid="cardLoadFee">
              <Money value={token.fee} currency={currency} />
            </Table.Cell>
            <Table.Cell data-testid="additionalProducts"></Table.Cell>
            <Table.Cell data-testid="paymentMethods"></Table.Cell>
            <Table.Cell data-testid="paymentAmount"></Table.Cell>
            <Table.Cell
              className="text-center"
              data-testid="expand"
            ></Table.Cell>
          </Table.Row>
        ))}

      <LoadCardPrintReceipt
        isOpen={printReceiptOpen}
        onClose={printReceiptCloseHandler}
        loadCards={load}
      />
    </>
  );
}

export default function MyPortalCardsLoad() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    loadingMyPortalCardsLoad,
    receivedMyPortalCardsLoad,
    errorMyPortalCardsLoad,
    meta,
    loadingMyPortalCardsLoadSummary,
    receivedMyPortalCardsLoadSummary,
    errorMyPortalCardsLoadSummary,
  } = useSelector((state) => state.reportingMyPortalCardsLoad);
  const { receivedUsersList, errorUsersList } = useSelector(
    (state) => state.usersList,
  );
  const { selectedTag } = useSelector((state) => state.tag);
  const { receivedDesksList, errorDesksList } = useSelector(
    (state) => state.desksList,
  );
  const { appCount, loadingCSV, validationErrors } = useSelector(
    (state) => state.commonReducer,
  );

  const [fromDate, setFromDate] = useState(new Date().setHours(0, 0, 0, 0));
  const [toDate, setToDate] = useState(null);
  const formatWithoutTimeZone = "Y-MM-dd'T'HH:mm:ss";
  const [formattedFromDate, setFormattedFromDate] = useState(
    format(new Date().setHours(0, 0, 0, 0), formatWithoutTimeZone),
  );
  const [formattedToDate, setFormattedToDate] = useState(
    format(new Date().setHours(23, 30, 0, 0), formatWithoutTimeZone),
  );
  const [selectedUser, setSelectedUser] = useState({
    id: null,
    label: t('All'),
    firstName: '',
    lastName: '',
  });
  const [selectedDesk, setSelectedDesk] = useState({
    id: null,
    label: t('All'),
  });
  const [selectedPage, setSelectedPage] = useState(1);
  const pageRef = useRef(null);
  const [rowIsOpen, setRowIsOpen] = useState(null);

  const usersOnchangeHandler = (user) => {
    setSelectedUser(user);
  };

  const deskChangeHandler = (desk) => {
    setSelectedDesk(desk);
  };

  const getMyPortalCardsLoadHandler = () => {
    dispatch(
      getMyPortalCardsLoads(
        formattedFromDate,
        formattedToDate,
        selectedPage,
        selectedUser,
        selectedDesk,
      ),
    );

    dispatch(
      getMyPortalCardsLoadsSummary(
        formattedFromDate,
        formattedToDate,
        selectedUser,
        selectedDesk,
      ),
    );
  };

  const fromDatePickHandler = (date) => {
    const formattedDate = format(date, formatWithoutTimeZone);
    setFromDate(date);
    setFormattedFromDate(formattedDate);
  };

  const toDatePickHandler = (date) => {
    const formattedDate = format(date, formatWithoutTimeZone);
    setToDate(date);
    setFormattedToDate(formattedDate);
  };

  const handlePagination = (page) => {
    setSelectedPage(page.selected + 1);
    dispatch(
      getMyPortalCardsLoads(
        formattedFromDate,
        formattedToDate,
        page.selected + 1,
        selectedUser,
        selectedDesk,
      ),
    );
  };

  const exportToCSVHandler = () => {
    dispatch(
      getMyPortalCardsLoadCSVExport(
        formattedFromDate,
        formattedToDate,
        selectedUser,
        selectedDesk,
      ),
    );
  };

  useEffect(() => {
    Userpilot.reload();

    if (appCount === 0) {
      getMyPortalCardsLoadHandler();
    }
  }, []);

  return (
    <Container
      title={t('myportal-operations.myportal-cards-load.myportal-cards-loads')}
      loading={loadingMyPortalCardsLoad || loadingMyPortalCardsLoadSummary}
    >
      <div
        className={`grid grid-cols-1 ${
          receivedDesksList?.length !== 0
            ? 'md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5'
            : 'md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4'
        } gap-5 items-end `}
      >
        <DatePickerField
          label={t('Choose-the-date')}
          disabled={loadingMyPortalCardsLoad || loadingMyPortalCardsLoadSummary}
          selected={fromDate}
          onChange={(date) => fromDatePickHandler(date)}
          showTimeSelect={true}
        />

        <DatePickerField
          label={`${t('End-date')}(${t('Optional')})`}
          disabled={loadingMyPortalCardsLoad || loadingMyPortalCardsLoadSummary}
          selected={toDate}
          onChange={(date) => toDatePickHandler(date)}
          showTimeSelect={true}
        />

        {receivedDesksList?.length !== 0 && (
          <SelectDeskField
            isDisabled={
              loadingMyPortalCardsLoad || loadingMyPortalCardsLoadSummary
            }
            value={selectedDesk}
            onChange={deskChangeHandler}
          />
        )}

        {receivedUsersList?.length !== 0 && (
          <SelectUserField
            isDisabled={
              loadingMyPortalCardsLoad || loadingMyPortalCardsLoadSummary
            }
            value={selectedUser}
            onChange={usersOnchangeHandler}
          />
        )}

        <button
          disabled={loadingMyPortalCardsLoad || loadingMyPortalCardsLoadSummary}
          onClick={getMyPortalCardsLoadHandler}
          className="h-10 py-2 border flex flex-row items-center justify-center rounded  hover:bg-opacity-75 bg-gfCoral font-MulishBold  px-4 focus:outline-none"
        >
          {loadingMyPortalCardsLoad && <ButtonLoading color={GF_DARK_BLUE} />}
          {t('Go')}!
        </button>
      </div>

      <div className="flex flex-col mt-20 space-y-5">
        {receivedMyPortalCardsLoadSummary !== null && (
          <div>
            <div className="grid grid-cols-1  md:grid-cols-3 gap-5 md:gap-20">
              {receivedMyPortalCardsLoadSummary?.transactions_total !== 0 && (
                <CardInfoWidget
                  bottomLabel={t('Number-of-transactions')}
                  isCurrency={false}
                  value={receivedMyPortalCardsLoadSummary.transactions_total}
                  labelColor={'text-gfDarkBlue'}
                  valueColor={'text-gfDarkBlue'}
                />
              )}
              {receivedMyPortalCardsLoadSummary?.card_total !== 0 && (
                <CardInfoWidget
                  bottomLabel={t('loaded-cards')}
                  isCurrency={false}
                  value={receivedMyPortalCardsLoadSummary.card_total}
                  labelColor={'text-gfDarkBlue'}
                  valueColor={'text-gfDarkBlue'}
                />
              )}
              {receivedMyPortalCardsLoadSummary?.total_amount !== 0 && (
                <CardInfoWidget
                  bottomLabel={t('Total-amount')}
                  isCurrency
                  value={receivedMyPortalCardsLoadSummary.total_amount}
                  labelColor={'text-gfLightBlue'}
                  valueColor={'text-gfLightBlue'}
                />
              )}
            </div>

            {receivedMyPortalCardsLoadSummary?.payment_summary?.length !==
              0 && (
              <div className="flex flex-col">
                <div className="font-MulishBlack mt-4 mb-2 ">
                  {t('Payment-methods')}
                </div>
                <div className="flex flex-wrap -mx-6 overflow-hidden sm:-mx-1 md:-mx-4 lg:-mx-8 xl:-mx-2 border rounded bg-gfSand">
                  {receivedMyPortalCardsLoadSummary?.payment_summary?.map(
                    (item) => (
                      <div
                        key={`${item.label}-${randomString(5)}`}
                        className="my-6 px-6 w-1/6 overflow-hidden sm:my-1 sm:px-1 sm:w-1/3 md:my-4 md:px-4 md:w-1/2 lg:my-8 lg:px-8 lg:w-1/3 xl:my-2 xl:px-2 xl:w-1/6"
                      >
                        <div className="flex flex-col">
                          <span className="font-MulishBold ">{item.name}</span>
                          <div className="flex flex-row">
                            {t('Transactions')}: &nbsp;
                            <div className="text-gfDarkBlue font-MulishBold">
                              {item.transactions}
                            </div>
                          </div>
                          <div className="flex flex-row">
                            {t('Total')}: &nbsp;
                            <div className="flex justify-start text-gfLightBlue font-MulishBold">
                              <CurrencyFormat
                                decimalScale={2}
                                fixedDecimalScale={true}
                                value={item.amount}
                                displayType={'text'}
                                thousandSeparator={true}
                              />
                              <span className="ml-2">{`EUR`}</span>
                            </div>
                          </div>
                          <div className="flex flex-row ">
                            {t('loaded-cards')}: &nbsp;
                            <span className="text-gfDarkBlue font-MulishBold">
                              {item.cards}
                            </span>
                          </div>
                        </div>
                      </div>
                    ),
                  )}
                </div>
              </div>
            )}
          </div>
        )}

        {receivedMyPortalCardsLoad !== null &&
          receivedMyPortalCardsLoad?.length !== 0 && (
            <div className="flex flex-col md:flex-row md:justify-between md:items-center my-4">
              <div className="flex">
                <button
                  disabled={
                    loadingMyPortalCardsLoad || loadingMyPortalCardsLoadSummary
                  }
                  id="exportCsvButton"
                  onClick={exportToCSVHandler}
                  className="px-4 py-2 rounded focus:outline-none bg-gfCoral flex flex-row justify-center items-center font-MulishBold"
                >
                  {loadingCSV && <ButtonLoading color={GF_DARK_BLUE} />}
                  {t('Export-as-a-CSV-file')}
                </button>
              </div>

              {meta?.total >
                window?.appConfig.REACT_APP_PAGINATION_PER_PAGE_ROWS && (
                <div>
                  <ReactPaginate
                    ref={pageRef}
                    previousLabel={'prev'}
                    nextLabel={'next'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={
                      meta.total /
                      window?.appConfig.REACT_APP_PAGINATION_PER_PAGE_ROWS
                    }
                    marginPagesDisplayed={4}
                    pageRangeDisplayed={5}
                    onPageChange={handlePagination}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                  />
                </div>
              )}
            </div>
          )}

        {receivedMyPortalCardsLoad?.length === 0 && (
          <MessageAlert message={t('There-are-no-orders-to-display')} />
        )}

        {errorDesksList && (
          <div className="mt-10">
            <FailAlert message={errorDesksList.message} />
          </div>
        )}

        {errorUsersList && (
          <div className="mt-10">
            <FailAlert message={errorUsersList.message} />
          </div>
        )}

        {errorMyPortalCardsLoadSummary && (
          <div className="mt-10">
            <FailAlert message={errorMyPortalCardsLoadSummary.message} />
          </div>
        )}

        {meta !== null && errorMyPortalCardsLoad && (
          <div className="mt-10">
            <FailAlert message={errorMyPortalCardsLoad.message} />
          </div>
        )}

        {validationErrors && (
          <div className="mt-10">
            <ValidationErrors {...validationErrors} />
          </div>
        )}

        {receivedMyPortalCardsLoad !== null &&
          receivedMyPortalCardsLoad?.length !== 0 && (
            <div className=" overflow-x-auto sm:overflow-x-auto md:overflow-x-auto rounded border ">
              <div className="align-middle inline-block min-w-full  ">
                <div className="shadow overflow-hidden  ">
                  <Table>
                    <Table.Head>
                      <tr>
                        <Table.Header>{t('Date')}</Table.Header>
                        <Table.Header>{t('Ticket-number')}</Table.Header>
                        <Table.Header>{t('User')}</Table.Header>
                        <Table.Header>{t('Token')}</Table.Header>
                        <Table.Header>{t('Card-load')}</Table.Header>
                        <Table.Header>{t('card-load-fee')}</Table.Header>
                        <Table.Header>{t('Additional-product')}</Table.Header>
                        <Table.Header>{t('Payment-method')}</Table.Header>
                        <Table.Header>{t('Amount')}</Table.Header>
                        <Table.Header>{t('Action')}</Table.Header>
                      </tr>
                    </Table.Head>

                    <Table.Body>
                      {receivedMyPortalCardsLoad?.map((load) => (
                        <LoadRow
                          setRowIsOpen={setRowIsOpen}
                          rowIsOpen={rowIsOpen === load.ticket_number}
                          key={load.ticket_number}
                          load={load}
                          currency={selectedTag.currency}
                        />
                      ))}
                    </Table.Body>
                  </Table>
                </div>
              </div>
            </div>
          )}
      </div>
    </Container>
  );
}
