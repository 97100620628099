import { useMemo } from 'react';
import { useSelector } from 'react-redux';

function useCustomerFields() {
  const { fields } = useSelector((state) => state.loadCard);

  const hasCustomerFields = useMemo(() => (fields || []).length > 0, [fields]);

  return {
    fields: fields || [],
    hasCustomerFields,
  };
}

export default useCustomerFields;
