import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useCustomerFields from '../../../hooks/useCustomerFields';
import { FormControl, FormField, FormItem, FormMessage } from '../../Form';
import TextField from '../../Input/TextField';
import SelectField from '../../Input/SelectField';

function Field({ field }) {
  const { t } = useTranslation();
  const { type } = field;

  const isRequired = useMemo(() => field.amount_for_required === 0, [field]);
  const rules = useMemo(() => {
    const schema = {};

    if (isRequired) {
      schema.required = t('Required');
    }

    if (type === 'tel') {
      schema.validate = (value) => {
        if (isNaN(value)) {
          return t('Must-be-a-number');
        }

        return null;
      };
    }

    if (type === 'email') {
      schema.pattern = {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        message: t('Invalid-email-address'),
      };
    }

    return schema;
  }, [t, isRequired, type]);

  const { label } = field;

  const labelParsed = useMemo(() => {
    return `${t(label)} ${isRequired ? '*' : ''}`;
  }, [label, isRequired, t]);

  return (
    <FormField
      name={`fields.${field.id}`}
      rules={rules}
      defaultValue={''}
      render={(formField) => {
        if (type === 'select') {
          const options = field.options.map((option) => ({
            label: option.label,
            value: option.id,
          }));

          const selected = options.find(
            (option) => option.value === formField.value,
          );

          return (
            <FormItem>
              <FormControl>
                <SelectField
                  ref={formField.ref}
                  onChange={(value) => formField.onChange(value.value)}
                  isSearchable
                  options={options}
                  value={selected ? { label: selected.label } : null}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          );
        }

        return (
          <FormItem>
            <FormControl>
              <TextField type="text" {...formField} placeholder={labelParsed} />
            </FormControl>
            <FormMessage />
          </FormItem>
        );
      }}
    />
  );
}

function CustomerInformationField() {
  const { fields } = useCustomerFields();

  return (
    <div className="grid grid-cols-2 gap-4">
      {fields.map((field) => (
        <Field field={field} key={field.id} />
      ))}
    </div>
  );
}

export default CustomerInformationField;
