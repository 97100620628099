import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { sumBy } from 'lodash';
import useAdditionalProducts from './useAdditionalProducts';
import {
  ERROR_LOAD_CARDS,
  LOADING_LOAD_CARDS,
  SUCCESS_LOAD_CARDS,
} from '../actions/LoadCardActions';
import { issuingAPI } from '../services/Axios';
import { useTicketNumber } from './useTicketNumber';
import { generateOperationID } from '../utils/Helper';

export default function useLoadCard({
  cards,
  additionalProducts,
  paymentMethods,
  onRemoveCard,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { generate: generateTicketNumber } = useTicketNumber();
  const { selectedTerminal } = useSelector((state) => state.terminal);
  const { tagCurrency, selectedTag } = useSelector((state) => state.tag);
  const { findById } = useAdditionalProducts();

  const hasPurchaseFee = useMemo(
    () => selectedTag.purchase_fee_enabled,
    [selectedTag],
  );

  const purchaseFee = useMemo(
    () => selectedTag.purchase_fee.amount_with_vat ?? 0,
    [selectedTag],
  );

  const selectedProducts = useMemo(
    () =>
      additionalProducts
        .filter((product) => product.product && product.quantity > 0)
        .map(({ product, quantity }) => ({
          product: findById(product),
          quantity: parseInt(quantity),
        })),
    [additionalProducts, findById],
  );

  const lineItems = useMemo(() => {
    let items = [];

    items = cards.map((card, index) => ({
      name: `${t('Card')} (${card.token})`,
      amount: card.amount,
      currency: tagCurrency,
      onRemove: () => onRemoveCard(index),
    }));

    for (const selectedProduct of selectedProducts) {
      const amount =
        selectedProduct.product.total_price * selectedProduct.quantity;

      items.push({
        name: `${selectedProduct.product.name} (x${selectedProduct.quantity})`,
        amount,
        currency: selectedProduct.product.currency,
      });
    }

    if (hasPurchaseFee) {
      items.push({
        name: `${t('place-b2b-order.purchase-fee.line-item.name')} (x${cards.length})`,
        amount: purchaseFee * cards.length,
        currency: tagCurrency,
        description: t('place-b2b-order.purchase-fee.line-item.description'),
      });
    }

    return items;
  }, [cards, selectedProducts, hasPurchaseFee, purchaseFee, t, onRemoveCard]);

  const totalAmount = useMemo(
    () => sumBy(lineItems, (item) => parseFloat(item.amount)),
    [lineItems],
  );
  const sumOfPayments = useMemo(() => {
    if (paymentMethods.length === 1) {
      return totalAmount;
    }

    return sumBy(paymentMethods, (method) => parseFloat(method.amount));
  }, [paymentMethods, totalAmount]);

  const isTotalAmountCovered = useMemo(
    () => totalAmount === sumOfPayments,
    [totalAmount, sumOfPayments],
  );

  async function placeOrder(payload) {
    dispatch({ type: LOADING_LOAD_CARDS });

    const ticketNumber = await generateTicketNumber();

    try {
      await issuingAPI.post(
        `/tag/${selectedTag.id}/ticket/${ticketNumber}/load-card/with-fee?terminal_id=${selectedTerminal?.id}`,
        payload,
        {
          headers: {
            'operation-id': generateOperationID(),
          },
        },
      );

      dispatch({ type: SUCCESS_LOAD_CARDS, payload: null });

      return ticketNumber;
    } catch (error) {
      dispatch({ type: ERROR_LOAD_CARDS, payload: error });
    }

    return null;
  }

  return {
    lineItems,
    totalAmount,
    isTotalAmountCovered,
    hasPurchaseFee,
    purchaseFee,
    placeOrder,
  };
}
